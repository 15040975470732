@import '@quno/patient-journey/styles/variables.scss';

.container {
  border-radius: var(--spacing-1);
  color: var(--textColor-primary);
  font-family: var(--fontFamily-netto);
  font-size: var(--text-2xl-size);
  padding: var(--spacing-1) var(--spacing-2);
  width: max-content;
  margin-right: var(--spacing-4);
  cursor: pointer;

  @media (min-width: $screens-lg) {
    border-width: 0;
    font-size: var(--text-base-size);
    padding: 0;
    width: auto;
    position: relative;
    margin-right: 0;
  }

  span.active {
    position: relative;
    &:before {
      content: '';
      background-color: #ffca58;
      bottom: 0;
      height: 3px;
      width: 100%;
      position: absolute;
      transform: scaleX(1);
      @media (min-width: $screens-lg) {
        content: '';
        background-color: #ffca58;
        bottom: 0;
        height: 3px;
        width: 100%;
        left: 0;
        position: absolute;
        transform: scaleX(1);
      }
    }
  }
}

.select {
  background-color: var(--color-white);
  overflow: hidden;
  box-shadow: 0 4px 4px -4px var(--color-silver);
  position: absolute;
  border: 1px solid #e0e3eb;
  right: 0;
  top: 67px;
  margin-right: var(--spacing-4);
  border-radius: 8px;

  @media (min-width: $screens-lg) {
    box-shadow: 0px 4px 20px 4px rgba(0, 0, 0, 0.15);
    top: 45px;
    right: 0;
    width: max-content;
    margin-right: 0;
  }
}

.selectContainer {
  padding-top: var(--spacing-4);
  position: absolute;
  z-index: 10;
  left: 0;
  width: 100vw;
  top: 0;

  @media (min-width: $screens-lg) {
    bottom: 0;
    min-width: max-content;
    width: max-content;
    padding-top: 0;
    position: static;
  }
}

a.animate {
  &:before {
    content: ' ';
    background-color: var(--color-primary);
    bottom: var(--spacing-1);
    height: 1px;
    left: 0;
    margin-left: var(--spacing-0-5);
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    visibility: hidden;
  }

  &:hover:before {
    transform: scaleX(1);
    visibility: visible;
  }
}

.icon {
  transition: transform 0.3s;

  &.iconOpen {
    transform: rotate(-180deg);
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-2-5);
  font-family: var(--fontFamily-inter);
  font-size: var(--text-base-size);
  color: var(--color-black);
}
