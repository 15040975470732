@import '@quno/patient-journey/styles/variables.scss';

.footer {
  width: 100%;
  flex-direction: column;
  background-color: #004052;

  .wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: $screens-xl;
    padding: var(--spacing-2) var(--spacing-3);

    @media (min-width: $screens-md) {
      padding-inline: var(--spacing-4);
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding-block: 40px;

    & a {
      color: var(--color-white);
      text-decoration: none;
      font-size: var(--text-base-size);
    }

    @media (min-width: $screens-lg) {
      flex-direction: row;
      gap: 36px;
      margin: 0;
    }
  }
}
