@import '@quno/patient-journey/styles/variables.scss';

.container {
  margin-inline: auto;
  max-width: $screens-lg;
  overflow: hidden;
  position: relative;
  font-family: var(--fontFamily-inter);

  @media (min-width: $screens-sm) {
    padding-inline: var(--spacing-5);
  }

  @media (min-width: $screens-lg) {
    padding-inline: var(--spacing-4);
  }
}

.content {
  margin-bottom: var(--spacing-4);
  padding-bottom: var(--spacing-1);

  @media (min-width: $screens-lg) {
    margin-bottom: 0;
    width: 28rem;
  }
}

.contentWrapper {
  padding: var(--spacing-28) var(--spacing-5) var(--spacing-10);
  position: relative;
  text-align: center;

  @media (min-width: $screens-sm) {
    padding-inline: 0;
  }

  @media (min-width: $screens-md) {
    grid-column: span 1 / span 1;
    margin-right: var(--spacing-8);
    padding-bottom: 0;
    padding-top: var(--spacing-20);
    text-align: left;
  }

  @media (min-width: $screens-lg) {
    grid-column: span 2 / span 2;
    margin-right: 0;
  }
}

.errorCode {
  font-weight: 600;
  padding-bottom: var(--spacing-5);
}

.page {
  position: relative;
}

.title {
  font-size: 44px;
  padding-bottom: var(--spacing-10);
  font-family: var(--fontFamily-inter);

  @media (min-width: $screens-sm) {
    font-size: 52px;
  }
}

.wrapper {
  padding-top: var(--spacing-10);

  @media (min-width: $screens-md) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-bottom: var(--spacing-12);
  }

  @media (min-width: $screens-lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding-bottom: var(--spacing-28);
  }
}
