@import '@quno/patient-journey/styles/variables.scss';

.extended {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 29px;
}

.header {
  background-color: var(--color-white);
  z-index: 30;
}

.inner {
  display: flex;
  align-items: center;
  height: var(--spacing-20);
  justify-content: space-between;
  margin: 0 auto;
  max-width: $screens-xl;

  @media (min-width: $screens-md) {
    padding-block: var(--spacing-5);
    padding-inline: var(--spacing-4);
  }

  @media (min-width: $screens-lg) {
    justify-content: space-between;
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 60px;

  @media (min-width: $screens-lg) {
    justify-content: flex-start;
  }

  img {
    object-fit: contain;
    width: auto;
    height: 100%;
  }

  .logo {
    order: 9999;
    padding-top: var(--spacing-1);
    padding-left: var(--spacing-4);

    @media (min-width: $screens-lg) {
      order: -9999;
    }

    svg {
      height: var(--spacing-8);
      width: var(--spacing-44);
    }
  }
}

.mobileWrapperHidden {
  display: none;
}

.mobileWrapper {
  display: flex;
  flex-direction: row;
  background-color: var(--color-white);
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  height: 50px;

  @media (min-width: $screens-lg) {
    display: none;
  }

  & nav ul li:last-child {
    padding-right: var(--spacing-2-5);
  }

  &Slider {
    padding-left: var(--spacing-4);
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    scroll-padding-inline: var(--spacing-4);
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 5%;
    height: 100%;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 90%
    );
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 5%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 90%
    );
  }
}

.search {
  display: flex;
  align-items: center;
  color: var(--textColor-primary);
  cursor: pointer;
  font-family: var(--fontFamily-netto);
  position: absolute;
  right: 0;
  text-transform: uppercase;
  margin-right: 15px;

  @media (min-width: $screens-lg) {
    margin-right: var(--spacing-6);
    position: relative;
  }
}

.searchSvg {
  width: var(--spacing-6);

  @media (min-width: $screens-lg) {
    width: var(--spacing-6);
  }
}

.searchLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.searchText {
  display: none;
  font-weight: 700;
  padding-block: var(--spacing-2);

  @media (min-width: $screens-lg) {
    display: block;
  }
}

.link {
  display: flex !important;
  align-items: center !important;
  padding: 0.75rem !important;
  text-decoration-line: none !important;
  font-family: var(--fontFamily-inter);
  font-size: var(--text-base-size);
  color: var(--color-black) !important;

  &:hover {
    background-color: #f2f5f9;
  }
}

.wrapper {
  background-color: var(--color-white);
  border-bottom: 0.5px solid #cacaca;
  position: relative;
  z-index: 40;
}
